<template>
  <div class="mdt-room-detail" v-show="showing_room">
    <div class="row m-0">
      <div class="d-none d-lg-block col-lg-2" @click="hideMeetRoomDetail()"></div>
      <div class="col-md-12 col-lg-10 content-room">
        <div class="_header">
          <span></span>
          <div class="d-flex justify-content-center">
            <svg @click="goToUpdateMeet" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg" class="cursor-pointer mx-1"
              v-if="canModifyMeet(showing_room && showing_room.members)">
              <path
                d="M14.059 9.02L14.979 9.94L5.91902 19H4.99902V18.08L14.059 9.02ZM17.659 3C17.409 3 17.149 3.1 16.959 3.29L15.129 5.12L18.879 8.87L20.709 7.04C21.099 6.65 21.099 6.02 20.709 5.63L18.369 3.29C18.169 3.09 17.919 3 17.659 3ZM14.059 6.19L2.99902 17.25V21H6.74902L17.809 9.94L14.059 6.19Z"
                fill="black" />
            </svg>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
              @click="onLeaveMeet" class="cursor-pointer mx-1">
              <path
                d="M17 8L15.59 9.41L17.17 11H9V13H17.17L15.59 14.58L17 16L21 12L17 8ZM5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z"
                fill="black" />
            </svg>

            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer" @click="hideMeetRoomDetail()">
              <path d="M6.5293 17.9998L18.5291 6" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M18.5291 17.9998L6.5293 6" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div>
          <div v-show="showing_room && !loading_room">
            <div class="row m-0 my-3">
              <div class="col-md-6">
                <p class="robo-24-500 mb-1 txt-black">
                  {{ (showing_room && showing_room.name) || "..." }}
                </p>
                <div v-if="showing_room" class="robo-18-400 mb-0 txt-black" v-html="showing_room.description"></div>
              </div>
              <div class="col-md-6">
                <div class="w-100 d-flex justify-content-end align-items-start">
                  <button v-if="showing_room &&
    !loading_room &&
    (canModify || today_concluding)
    " class="btn txt-pri bg-white bd-pri mb-3 mr-2" @click="showModalConcluding(true)">
                    {{ $t("lbl_report") }}
                  </button>
                  <button v-if="showing_room && !loading_room" class="btn bg-pri bd-pri text-white"
                    @click="goToRoom(showing_room)">
                    {{ $t("lbl_join_room") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="mdt-detail-body">
              <div class="row m-0 py-3 border-top">
                <div class="col-3">
                  <p class="robo-18-500 mb-0 txt-black">{{ $t("lbl_time") }}</p>
                </div>
                <div class="col-9">
                  <p class="robo-16-400 mb-0 txt-black">
                    {{
    (showing_room &&
      mdtUtils.getTimeStringDetail(showing_room)) ||
    "..."
  }}
                  </p>
                </div>
              </div>
              <div class="row m-0 py-3 border-top">
                <div class="col-3">
                  <p class="robo-18-500 mb-0 txt-black">
                    {{ $t("lbl_location") }}
                  </p>
                </div>
                <div class="col-9">
                  <p class="robo-16-400 mb-0 txt-black">
                    {{ (showing_room && showing_room.location) || "..." }}
                  </p>
                </div>
              </div>
              <div class="row m-0 py-3 border-top border-bottom mb-4" v-if="showing_room"
                :set="(link = getMeetLink(showing_room.room))">
                <div class="col-3">
                  <p class="robo-18-500 mb-0 txt-black">
                    {{ $t("lbl_link_room") }}
                  </p>
                </div>
                <div class="col-9 row m-0">
                  <div class="col-6">
                    <p class="block robo-16-400 mb-0 txt-black max-line-1 truncate">
                      {{ link || "..." }}
                    </p>
                  </div>
                  <div class="col-6 d-flex align-items-center justify-content-end">
                    <a href="javascript:;" @click="copyLink(link)"
                      class="txt-pri text-decoration-underline txt-16-400 d-flex align-items-center">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="mr-2">
                        <path
                          d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.60003C3.60003 22 2.00003 20.4 2.00003 16.4V12.6C2.00003 8.6 3.60003 7 7.60003 7H10.6"
                          stroke="#20419B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z" stroke="#20419B"
                          stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.6 2H15.6" stroke="#20419B" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M7.00003 5C7.00003 3.34 8.34003 2 10 2H12.62" stroke="#20419B" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22 8V14.19C22 15.74 20.74 17 19.19 17" stroke="#20419B" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke="#20419B" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      {{ $t("lbl_copy_link") }}
                    </a>
                  </div>
                </div>
              </div>
              <!-- member -->
              <div class="row m-0 mb-4">
                <div class="col-12 d-flex justify-content-between align-items-center mb-2">
                  <p class="robo-18-500 txt-black">{{ $t("lbl_member") }}</p>
                  <!-- <div
              class="input-group border select-date radius-10"
              v-show="showing_room && showing_room.repeat_type"
            >
              <div class="input-group-text border-0 bg-white radius-10">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2V5"
                    stroke="#23262F"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 2V5"
                    stroke="#23262F"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.5 9.09009H20.5"
                    stroke="#23262F"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                    stroke="#23262F"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.6947 13.7H15.7037"
                    stroke="#23262F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.6947 16.7H15.7037"
                    stroke="#23262F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.9955 13.7H12.0045"
                    stroke="#23262F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.9955 16.7H12.0045"
                    stroke="#23262F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.29431 13.7H8.30329"
                    stroke="#23262F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.29431 16.7H8.30329"
                    stroke="#23262F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input id="dateMeetRetrait" class="radius-10 form-select txt-black" />
                  </div>-->
                </div>
                <div class="col-lg-4 col-md-6" v-show="canModify">
                  <div class="d-flex align-items-center btn-add-someting px-2" @click="showAddMember(true)">
                    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="mr-2">
                      <circle cx="17" cy="16.5" r="16" stroke="#6D6E6F" stroke-linecap="round" stroke-dasharray="0 3" />
                      <rect x="16.0909" y="11.5" width="1.81818" height="10" fill="#6D6E6F" />
                      <rect x="22" y="15.5908" width="1.81818" height="10" transform="rotate(90 22 15.5908)"
                        fill="#6D6E6F" />
                    </svg>
                    <span class="robo-14-20">{{ $t("lbl_add_member") }}</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6" v-for="mem in room_members" :key="mem.id + 'mem'">
                  <div class="dropdown">
                    <div type="button" id="dropdownMenuButton" :data-toggle="canModify && mem.role !== 1 ? 'dropdown' : ''
    " :aria-haspopup="canModify && mem.role !== 1" :aria-expanded="!canModify || mem.role === 1">
                      <div class="d-flex align-items-center justify-content-between item-doctor p-0 bg-white" :class="canModify && mem.role !== 1
    ? 'form-select'
    : 'form-control cursor-normal'
    ">
                        <div class="d-flex align-items-center" :set="[
    (user = mem.user),
    (usr_name = getUserName(mem.user)),
  ]">
                          <span v-if="user && user.avatar" class="avatar avatar-sm avatar-rounded mr-2" :style="'background-image: url(' +
    getImageURL(user.avatar) +
    ')'
    "></span>
                          <span v-if="user && !user.avatar" class="avatar avatar-sm avatar-rounded mr-2">{{
    usr_name.charAt(0) }}</span>
                          <div>
                            <p class="robo-14-400 txt-black mb-0 max-line-1">
                              {{ usr_name }}
                            </p>
                            <p class="robo-12-400 txt-grey-900 mb-0 max-line-1">
                              {{ getRoleName(mem.role) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton"
                      v-show="canModify && mem.role != 1">
                      <a v-if="mem.role != 1" class="dropdown-item" href="javascript:;"
                        @click="changeRole(1, mem.user_id)">{{
    $t("multidisciplinary_board.lbl_mems_type_chairman")
  }}</a>
                      <a v-if="mem.role != 2" class="dropdown-item" href="javascript:;"
                        @click="changeRole(2, mem.user_id)">{{
    $t("multidisciplinary_board.lbl_mems_type_secretary")
  }}</a>
                      <a v-if="mem.role != 0" class="dropdown-item" href="javascript:;"
                        @click="changeRole(0, mem.user_id)">{{ $t("multidisciplinary_board.lbl_participant") }}</a>
                      <a v-if="mem &&
    user_id !== mem.user_id &&
    showing_room &&
    mem.user_id !== showing_room.user_id
    " class="dropdown-item" href="javascript:;" @click="removeMeetMember(mem)">{{
    $t("multidisciplinary_board.btn_remove") }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- member -->
              <!-- attach -->
              <div class="row m-0 mb-4">
                <div class="col-12 d-flex justify-content-between align-items-center mb-2">
                  <p class="robo-18-500 txt-black mb-0">
                    {{ $t("lbl_document") }}
                  </p>
                  <div class="input-group border select-date radius-10"
                    v-show="showing_room && showing_room.repeat_type">
                    <div class="input-group-text border-0 bg-white radius-10">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 2V5" stroke="#23262F" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16 2V5" stroke="#23262F" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M3.5 9.09009H20.5" stroke="#23262F" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                          stroke="#23262F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M15.6947 13.7H15.7037" stroke="#23262F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M15.6947 16.7H15.7037" stroke="#23262F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M11.9955 13.7H12.0045" stroke="#23262F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M11.9955 16.7H12.0045" stroke="#23262F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M8.29431 13.7H8.30329" stroke="#23262F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M8.29431 16.7H8.30329" stroke="#23262F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </div>
                    <input id="dateMeetRetrait" class="radius-10 form-select txt-black" />
                  </div>
                </div>

                <div class="d-flex align-items-center btn-add-someting px-2" @click="openAddAttach()">
                  <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="mr-2">
                    <circle cx="17" cy="16.5" r="16" stroke="#6D6E6F" stroke-linecap="round" stroke-dasharray="0 3" />
                    <rect x="16.0909" y="11.5" width="1.81818" height="10" fill="#6D6E6F" />
                    <rect x="22" y="15.5908" width="1.81818" height="10" transform="rotate(90 22 15.5908)"
                      fill="#6D6E6F" />
                  </svg>
                  <span class="robo-14-20">{{ $t("lbl_add_documents") }}</span>
                </div>
                <div v-for="item in attachments.data" :key="item.id + 'at'" class="attach-item"
                  @click="openDocAttach(item)">
                  <p class="mb-0 robo-16-500 txt-pri max-line-1">
                    {{ item.name }}
                  </p>
                  <p class="mb-0 robo-10-400 txt-grey-600 max-line-1">
                    <span>Tải lên: {{ formatDMY(item.created_at) }}</span>
                    <span v-if="item.date">- Ngày họp: {{ formatDMY(item.date) }}</span>
                  </p>
                </div>
                <div class="w-100 text-center mt-3 mb-0" v-if="showing_room">
                  <a href="javascript:;" class="txt-pri robo-16-400"
                    v-if="attachments.current_page < attachments.last_page"
                    @click="getMeetRoomAttachs(showing_room.id, true)">Xem thêm</a>
                </div>
              </div>
              <!-- !attach -->
            </div>
          </div>
          <div class="w-100">
            <div class="mdt-detail-body d-flex align-items-center justify-content-center" v-if="loading_room">
              <div class="spinner-border mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <MeetRoomAddMember ref="MeetRoomAddMember" v-if="showing_room != {} && !!showing_room"
            :showing_room="showing_room" :room_members="room_members" @refresh="refreshMember" />

          <div class="modal fade" id="modalConcluding">
            <div class="modal-dialog modal-full-width modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="d-flex justify-content-between p-3">
                  <h5 class="modal-title" id="modalConcluding"></h5>
                  <button type="button" class="close bg-white border-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="robo-18-500">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <MeetConcluding :meet_room="showing_room" @refresh="showModalConcluding"
                    @changeTodayConcluding="changeTodayConcluding" />
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
          <!-- /.modal -->
        </div>
        <div class="d-flex justify-content-end mr-2">
          <button v-if="canModifyMeet(showing_room && showing_room.members)" class="btn txt-red bg-white bd-red mb-5"
            @click="deleteMeet">
            {{ $t("lbl_delete_meeting_schedule") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeetRoomAddMember from './MeetRoomAddMember.vue'
import MeetConcluding from './MeetConcluding.vue'
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
export default {
  name: 'MeetRoomDetail',
  components: { MeetRoomAddMember, MeetConcluding },
  props: ['showing_room', 'loading_room'],
  data () {
    return {
      room_members: [],
      user_member: null,
      showing_date: window.moment(),
      showing_next_date: window.moment(),
      attachments: [],
      mdtUtils,
      appUtils,
      user_id: null,
      showing_disease: null,
      today_concluding: null,
      canModify: false
    }
  },
  mounted () {
    this.user_id = appUtils.getLocalUser()?.id
    if (this.showing_room) {
      this.processRoom(this.showing_room)
      this.canModify = this.canModifyMeet(this.showing_room?.members)
    }
  },
  watch: {
    showing_room (room) {
      this.processRoom(room)
      this.canModify = this.canModifyMeet(room?.members)
    }
  },
  methods: {
    async deleteMeet () {
      const id = this.showing_room?.id
      await this.$rf
        .getRequest('AuthRequest')
        .deleteMeetRoom(id)
        .then((r) => {
          this.hideMeetRoomDetail()
          this.$emit('refresh')
          this.$toast.open({
            message: 'Xóa thành công',
            type: 'success'
          })
        })
    },
    processRoom (room) {
      try {
        if (room) {
          let self = this
          let nextDate = self.getNextDate(room)
          self.showing_date = nextDate
          self.showing_next_date = nextDate
          let day = null
          let dfDate = window.moment().format('DD-MM-YYYY')
          if (room.repeat_type) {
            if (room.repeat_type === 2) {
              day = window.moment(room.date, 'YYYY-MM-DD').day()
            }
            dfDate = nextDate.clone().format('DD-MM-YYYY')
            self.showing_date = window.moment(dfDate, 'DD-MM-YYYY')
            window.flatpickr(document.getElementById('dateMeetRetrait'), {
              defaultDate: dfDate,
              minDate: room.date,
              dateFormat: 'd-m-Y',
              disable: [
                function (date) {
                  var dayOfThis = date.getDay()
                  return day ? dayOfThis !== day : false
                }
              ],
              onChange: function (date) {
                if (!date[0]) return
                let newDate = window.moment(date[0])
                if (newDate.clone().unix() >= window.moment().unix()) {
                  self.showing_next_date = newDate
                }
                self.showing_date = newDate
                self.getMeetRoomAttachs(room.id)
              }
            })
          }
          self.getMeetMembers(room.id)
          self.getMeetRoomAttachs(room.id)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getMeetMembers (id) {
      let self = this
      let params = {
        date: self.showing_date.format('DD-MM-YYYY')
      }
      self.$rf
        .getRequest('DoctorRequest')
        .getMeetRoomMembers(id, params)
        .then((r) => {
          self.room_members = r.data
          self.user_member = r.data.find((mu) => mu.user_id === self.user_id)
        })
    },
    async getMeetRoomAttachs (id, is_next) {
      let self = this
      let page = 1
      if (!is_next) {
        self.attachments = []
      } else {
        page = this.params_disease.page + 1
      }
      let params = {
        date: self.showing_date.format('DD-MM-YYYY'),
        page: page
      }
      self.$rf
        .getRequest('DoctorRequest')
        .getMeetRoomAttachs(id, params)
        .then((r) => {
          if (r && r.data) {
            self.attachments.current_page = r.data.current_page
            if (!is_next) {
              self.attachments = r.data
            } else {
              if (r.data?.count) {
                let old = self.attachments.data
                let newObj = r.data.data
                self.attachments.data = old.concat(newObj)
              }
            }
          }
        })
    },
    formatDMYHm (date, time) {
      return mdtUtils.createDateTime(date, time).format('DD/MM/YYYY HH:mm')
    },
    formatHm (time) {
      return mdtUtils.createDateTime(time).format('HH:mm')
    },
    formatDMY (time) {
      return window.moment(time).format('DD/MM/YYYY')
    },
    async changeRole (role, user_id) {
      let self = this
      if (
        self.showing_room &&
        (role === 1 || role === 2 || role === 0) &&
        user_id
      ) {
        let params = {
          user_id: user_id,
          role: role
        }
        self.$rf
          .getRequest('DoctorRequest')
          .changeMeetMemberRole(self.showing_room.id, params)
          .then(() => {
            self.getMeetMembers(self.showing_room?.id)
          })
      }
    },
    async removeMeetMember (m) {
      let self = this
      let strcf = 'Bạn có chắc chắn muốn xóa khỏi cuộc họp này?'
      let r = confirm(strcf)
      if (!r) return
      this.$rf
        .getRequest('DoctorRequest')
        .removeMeetMember(m.id)
        .then((r) => {
          self.$toast.open({
            message: 'Xóa thành công',
            type: 'success'
          })
          self.getMeetMembers(self.showing_room?.id)
        })
    },
    getNextDate (room) {
      if (!room) return
      let now = window.moment()
      let date = window.moment(room.date)
      let type = room.repeat_type
      if (type) {
        if (date.clone().unix() <= now.clone().unix()) {
          if (type === 1) {
            return window.moment()
          } else if (type === 2) {
            if (date.day() === now.day()) {
              return window.moment()
            } else {
              return mdtUtils.getDateNextWeekByDay(date.day())
            }
          }
        } else {
          return date
        }
      } else {
        return window.moment(room.date)
      }
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    showAddMember (show) {
      // window.$('#modalAddMeetMember').modal(show ? 'show' : 'hide')
      this.$refs.MeetRoomAddMember.openModal(show)
    },
    showModalConcluding (show) {
      if (!show) this.$emit('refresh')
      window.$('#modalConcluding').modal(show ? 'show' : 'hide')
    },
    refreshMember () {
      this.getMeetMembers(this.showing_room?.id)
      this.showAddMember()
    },
    goToRoom (r) {
      let token = appUtils.getLocalToken()

      if (appUtils.isUseLiveHodoV2('meet')) {
        let domainOrigin = window.location?.origin
        let domain = ''

        if (domainOrigin?.includes('http://localhost')) {
          domain = 'http://localhost:8068'
        } else if (
          process.env.NODE_ENV === 'development' &&
          !domainOrigin?.includes('http://localhost')
        ) {
          domain = 'https://dev.v2.live.hodo.app/'
        } else {
          domain = 'https://v2.live.hodo.app'
        }

        // const role = roleObj['conference']
        const room100ms_id = r?.room?.room100ms_id
        const routeRaw = `${domain}/redirect.html?token=${token}&room_id=${room100ms_id}&meet_room_id=${r?.id
        }&type=meeting&role=${'host'}&lang=${this.$lang}`

        console.log(routeRaw)

        let linkElement = document.createElement('a')
        const linkId = `meeting_${room100ms_id}`
        linkElement.id = linkId
        linkElement.href = routeRaw
        window.document.body.appendChild(linkElement)

        const linkEl = document.getElementById(linkId)
        linkEl.setAttribute('rel', 'noopener noreferrer')
        !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
        linkEl.click()
        linkEl.remove()

        // window.open(routeRaw, "_blank");
      } else {
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.meet.hodo.com.vn/#/'
        } else {
          dm = 'https://meet.hodo.com.vn/#/'
        }

        let routeData = dm + '_app/' + r?.room?.room_name + '?token=' + token
        window.open(routeData, '_blank')
      }
    },
    copyLink (link) {
      let url = document.createElement('input')
      document.body.appendChild(url)
      url.value = link
      url.select()
      url.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: 'Đã sao chép vào bộ nhớ tạm',
        type: 'success'
      })
      url.remove()
    },
    getRoleName (r) {
      return this.$t(
        r === 1 ? 'lbl_chairman' : r === 2 ? 'lbl_secretary' : 'lbl_member'
      )
    },
    openAddAttach () {
      this.$router.push({
        name: 'MeetRoomAttachments',
        params: { id: this.showing_room.id }
      })
    },
    openDocAttach (doc) {
      window.open(doc.url)
    },
    getMeetLink (room) {
      if (appUtils.isUseLiveHodoV2('meet')) {
        let domainOrigin = window.location?.origin
        let domain = ''

        if (domainOrigin?.includes('http://localhost')) {
          domain = 'http://localhost:8068'
        } else if (
          process.env.NODE_ENV === 'development' &&
          !domainOrigin?.includes('http://localhost')
        ) {
          domain = 'https://dev.v2.live.hodo.app/'
        } else {
          domain = 'https://v2.live.hodo.app'
        }

        const room100ms_id = room?.room100ms_id
        const routeRaw = `${domain}/meeting/${room100ms_id}/host`
        return routeRaw
      } else {
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.meet.hodo.com.vn/#/'
        } else {
          dm = 'https://meet.hodo.com.vn/#/'
        }
        let routeData = dm + 'meet/' + room?.room_name
        return routeData
      }
    },
    getUserName (u) {
      return u.name || u.email
    },
    changeTodayConcluding (c) {
      this.today_concluding = c
    },
    goToUpdateMeet () {
      if (!this.showing_room) return
      if (!this.canModifyMeet(this.showing_room?.members)) return
      this.$router.push({
        name: 'MeetRoomUpdate',
        params: { id: this.showing_room.id }
      })
    },
    hideMeetRoomDetail () {
      this.$emit('hideMeetRoomDetail')
    },
    async onLeaveMeet () {
      let strcf = 'Bạn có chắc chắn muốn rời khỏi phòng họp này?'
      let r = confirm(strcf)
      if (!r) return
      if (!this.showing_room) return
      await this.$rf
        .getRequest('AuthRequest')
        .leaveMeet(this.showing_room.id)
        .then((r) => {
          this.$emit('refresh')
          this.hideMeetRoomDetail()
          this.$toast.open({
            message: 'Bạn đã rời khỏi phòng',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style scoped>
.bd-red {
  border: 1px solid #f18537;
}

.txt-red {
  color: #f18537;
}

.form-control {
  height: 100% !important;
}

.flatpickr-calendar.open {
  background-color: #e4e4e4 !important;
}

.select-date {
  color: black !important;
  width: 100%;
  max-width: 237px;
  height: 47px;
}

.search-disease {
  width: 339px;
  height: 47px;
}

.item-doctor {
  height: 47px;
  cursor: pointer;
  margin-bottom: 16px;
  padding-left: 5px;
}

.item-disease {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-disease-right {
  width: 130px;
}

.item-disease-left {
  width: calc(100% - 130px);
  display: flex;
  align-items: center;
}

.btn-add-someting {
  color: #6d6e6f;
  border: dashed 1.5px #d3d3d3;
  border-radius: 0.5rem;
  height: 47px;
  cursor: pointer;
  margin-bottom: 16px;
}

.mdt-detail-body {
  position: relative;
  height: calc(100vh - 200px);
  overflow-y: auto !important;
  overflow-x: hidden;
  border-radius: 0.5rem;
  padding-bottom: 50px;
}

.attach-item {
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 16px;
}

.cursor-normal {
  cursor: default !important;
}

.mdt-room-detail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1041;
}

.mdt-room-detail ._header {
  display: flex;
  justify-content: space-between;
}

.mdt-room-detail .content-room {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  padding-left: 32px;
  padding-right: 0;
  padding-top: 16px;
  background: #fff;
  height: 100vh;
}
</style>
