<template>
    <div class="row">
        <div class="col-md-6 px-3" v-show="canModify">
            <MeetConcludingForm @changeForm="changeForm" :concluding="concluding" :loading="loading"/>
        <div class="w-100 text-right">
            <button class="btn px-5 bg-pri bd-pri text-white" @click="submit" type="button" :disabled="loading">Lưu</button>
        </div>
        </div>
        <div class="d-none d-md-block col-md-6" :class="canModify ? 'col-md-6' : 'col-12'">
            <MeetConcludingTemplate :form="form" :save_pdf="save_pdf" @onSubmiting="onSubmiting" @exportError="exportError" :meet_room="meet_room"/>
        </div>
    </div>
</template>

<script>
import MeetConcludingForm from './MeetConcludingForm.vue'
import MeetConcludingTemplate from '../../templates/Meets/MeetConcludingTemplate.vue'
export default {
  name: 'MeetConcluding',
  components: {MeetConcludingForm, MeetConcludingTemplate},
  props: ['meet_room'],
  data () {
    return {
      form: {
        code: '',
        title: '',
        sub_title: '',
        conclusion: '',
        send_to: '',
        meet_time: window.moment().format('YYYY-MM-DDTHH:mm'),
        location: '',
        chairing_list: '',
        attendees: '',
        content: '',
        recipients: '',
        sign_name: '',
        sign_label: ''
      },
      concluding: null,
      save_pdf: 0,
      loading: false,
      canModify: false
    }
  },
  watch: {
    meet_room: {
      handler (data) {
        if (data) {
          this.getTodayMeetRoomConcluding(data.id)
          this.canModify = this.canModifyMeet(data?.members)
        }
      },
      deep: true
    }
  },
  methods: {
    changeForm (data) {
      this.form = data
    },
    submit () {
      this.loading = true
      this.save_pdf++
    },
    formatHmDMY (date) {
      let string = window.moment(date).format('HH:mm DD/MM/YYYY')
      string.split(' ').join(' ngày ')
      return string
    },
    onSubmiting (file) {
      if (!this.meet_room) return
      this.loading = true
      if (this.concluding) {
        this.updateMeetRoomConcluding(file)
      } else {
        this.createMeetRoomConcluding(file)
      }
    },
    async createMeetRoomConcluding (file = null) {
      let self = this
      if (!self.meet_room) return
      let params = {...this.form}
      params.meet_time = window.moment(self.form.meet_time).format('HH:mm DD-MM-YYYY')
      if (file) params.file = file
      let formData = new FormData()
      for (var key in params) {
        formData.append(key, (params[key] || ''))
      }
      await self.$rf.getRequest('DoctorRequest').createMeetRoomConcluding(this.meet_room.id, formData).then((r) => {
        self.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success'
        })
        self.$emit('refresh')
        self.getTodayMeetRoomConcluding(self.meet_room.id)
      }).finally(() => {
        self.loading = false
      })
    },
    async updateMeetRoomConcluding (file = null) {
      let self = this
      if (!self.concluding) return
      let params = {...this.form}
      params.meet_time = window.moment(self.form.meet_time).format('HH:mm DD-MM-YYYY')
      if (file) params.file = file
      let formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key])
      }
      await self.$rf.getRequest('DoctorRequest').updateMeetRoomConcluding(self.concluding.id, formData).then(() => {
        self.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success'
        })
        self.$emit('refresh')
        self.getTodayMeetRoomConcluding(self.meet_room.id)
      }).finally(() => {
        self.loading = false
      })
    },
    exportError (e) {
      console.log(e)
    },
    async getTodayMeetRoomConcluding (id) {
      let self = this
      await this.$rf.getRequest('DoctorRequest').getTodayMeetRoomConcluding(id).then((r) => {
        self.concluding = r.data
        if (r.data) self.$emit('changeTodayConcluding', r.data)
      })
    }
  }
}
</script>

<style scoped>

</style>