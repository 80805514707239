<template>
    <div class="modal fade" id="modal-start-med">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header bg-white">
                    <h5 class="modal-title text-uppercase txt-pri">Danh sách thuốc</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div v-if="medicines.data && medicines.data.length" class="modal-body">
                    <div v-for="med in medicines.data" class="row mb-1">
                        <div class="col-6 d-flex align-items-center">
                            <!-- <input type="checkbox" class="mr-2" disabled :checked="med.start_date"> -->
                            <p class="mb-0 robo-18-400 txt-pri">{{med.name}}</p>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <input class="form-control" type="date" v-model="med.start_date">
                        </div>
                    </div>
                </div>
                <div v-if="!medicines.data || !medicines.data.length" class="modal-body text-center">
                	<p class="mb-0 robo-18-400 txt-pri">Không có thuốc mới</p>
                </div>
                <div class="text-center mb-3">
                    <button v-if="medicines.data && medicines.data.length" class="btn radius-0 bg-pri bd-pri text-white robo-18-500 mr-2 mb-1" @click="startMed()">
                        Bắt đầu
                    </button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</template>
<script>
import appUtils from '../../utils/appUtils'
export default {

  name: 'StartMedModal',
  props: ['person_diseases_id'],
  data () {
    return {
      medicines: [],
      appUtils
    }
  },
  mounted () {
    let self = this
    this.$nextTick(function () {
      window.$('#modal-start-med').on('show.bs.modal', function () {
        self.getMedicines()
      })
    })
  },
  methods: {
    async getMedicines () {
      let self = this
      let now = window.moment().format('YYYY-MM-DD')
      let params = {
        is_new: 1,
        person_diseases_id: self.person_diseases_id,
        limit: 100
      }
      await self.$rf.getRequest('AdminRequest').getMedicines(params).then(resp => {
        self.medicines = resp.data
        if (self.medicines.data) {
          self.medicines.data.forEach((med) => {
            Object.assign(med, { start_date: now })
          })
          console.log(self.medicines)
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    async startMed () {
      let self = this
      if (self.medicines && self.medicines.data) {
        let params = {
          medicines: []
        }
        self.medicines.data.forEach((med) => {
          params.medicines.push({ id: med.id, start_date: window.moment(med.start_date).format('DD/MM/YYYY') })
        })
        await self.$rf.getRequest('AdminRequest').multiStartMultiMeds(params).then(resp => {
          window.$('#modal-start-med').modal('hide')
        }).catch((e) => {
          console.log(e)
        })
      }
    }
  }
}
</script>
<style lang="css" scoped>
.btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='inherit'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") no-repeat center center/.75rem auto;
}
</style>