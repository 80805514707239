<template>
  <div class="modal fade" id="modalAddMeetMember" tabindex="-1" role="dialog" aria-labelledby="modalAddMeetMemberTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content shadow-lg">
        <div class="d-flex justify-content-between p-3">
          <h5 class="modal-title" id="modalAddDiseaseLabel"></h5>
          <button type="button" class="close bg-white border-0" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="robo-18-500">&times;</span>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div v-loading="loading">
            <div class="member-wraper">
              <div class="border-bottom mb-3">
                <span class="mb-0 txt-pri bd-pri robo-14-500" style="border-bottom: 3px solid">
                  {{
                    $t('lbl_invite_members_to_join') }}
                </span>
              </div>
              <!-- <div class="mb-3">
          <p class="mb-2 robo-14-500">Thành viên định kỳ</p>
      </div>-->
              <div>
                <el-select class="w-full mr-2" v-model="doctorsSelected" multiple :multiple-limit="50" filterable
                  allow-create default-first-option clearable
                  :placeholder="$t('lbl_enter_the_name_or_email_of_the_doctor_you_want_to_invite')" remote
                  :remote-method="handleFilters" :loading="isFiltering" @change="handleSelectDoctor">
                  <el-option
                    class="border border-top-0 border-right-0 border-left-0 border-b border-gray-300 leading-none h-auto"
                    v-for="item in doctorsData" :key="item.id" :label="item.user.email" :value="item.user.email">
                    <div class="option-grid py-1">
                      <div class="option-avatar">
                        <span v-if="item.user && item.user.avatar" class="img-avatar" :style="'background-image: url(' +
                    getUrlImage(item.user.avatar) +
                    ');'
                    "></span>
                        <span v-else class="img-avatar">
                          {{
                    item.user.name
                      ? item.user.name.charAt(0)
                      : item.user.email
                        ? item.user.email.charAt(0)
                        : "H"
                  }}
                        </span>
                      </div>
                      <span class="option-name block font-medium">{{ item.name }}</span>
                      <span class="option-email block">{{ item.user.email }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>

              <button class="btn bg-pri bd-pri text-white save-btn" :disabled="loading" type="button" @click="onSubmit">
                {{
                    $t('lbl_send_invitations') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'MeetRoomAddMember',
  props: ['showing_room', 'room_members'],
  data () {
    return {
      members: [],
      doctors: [],
      show_list_mem: false,
      search: '',
      loading: false,
      doctorsData: [],
      doctorsSelected: [],
      isFiltering: false
    }
  },
  computed: {},
  mounted () {
    // this.getConectiveDoctors()
    this.doctorsData = this.filteredAndSorted()
  },
  methods: {
    filteredAndSorted () {
      if (!this.doctors || !this.doctors?.length) return []

      // function to compare names
      function compare (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }
      let search_string = appUtils.removeVietnameseTones(
        this.search.toLowerCase()
      )
      const result = this.doctors
        ?.filter(dr => {
          return (
            appUtils
              .removeVietnameseTones(dr.name.toLowerCase())
              .includes(search_string) ||
            (dr?.user?.email &&
              appUtils
                .removeVietnameseTones(dr?.user?.email.toLowerCase())
                .includes(search_string))
          )
        })
        .sort(compare)
      return result
    },
    async getConectiveDoctors () {
      const self = this
      if (!this.$clinicId) return
      let params = {
        clinic_id: this.$clinicId,
        circle: 1
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getConectiveDoctors(params)
        .then(r => {
          self.doctors = r.data
          let dw = r.data?.workspace
          let dc = r.data?.circle?.map(dc => {
            return Object.assign({ circle: 1 }, dc)
          })
          if (dw && dc) {
            self.doctors = dw.concat(dc)
          } else if (dw) {
            self.doctors = dw
          } else if (dc) {
            self.doctors = dc
          }
        })
    },
    onSubmit () {
      this.getCredential(
        this.showing_room.id,
        this.showing_room.room?.room_name
      )
    },
    async getCredential (id, roomName) {
      const self = this

      try {
        self.loading = true
        self.members = await Promise.all(
          self.doctorsSelected?.map(async doctor => {
            const data = self
              .filteredAndSorted()
              ?.find(d => d?.user?.email === doctor)
            if (!data || typeof data === 'undefined') {
              return {
                email: doctor
              }
            }
            return {
              doctor_id: data.id,
              email: doctor,
              name: data?.user?.name
            }
          })
        )

        if (self.members && self.members.length) {
          const credentials = {
            room_name: roomName,
            emails: []
          }
          self.members.map(m => {
            credentials.emails.push({
              email: m.email,
              default_name: m.name || 'HODO Default Name'
            })
          })

          await self.$rf
            .getRequest('DoctorRequest')
            .getCredentialMeetRoom(credentials)
            .then(async r => {
              await self.addMeetMembers(id, r.data?.data)
            })
            .catch(e => {
              console.log(e)
              self.loading = false
            })
        } else {
          self.finish()
        }
      } catch (error) {
        console.log(error)
      } finally {
        self.loading = false
      }
    },
    async addMeetMembers (id, credentials) {
      const self = this
      if (credentials?.user_ids && credentials?.user_ids.length) {
        const members = []
        credentials.user_ids.forEach(c => {
          members.push({ user_id: c })
        })
        await this.$rf
          .getRequest('AuthRequest')
          .addMeetMembers(id, { members: members })
          .then(r => {
            self.finish()
          })
          .catch(e => {
            console.log(e)
            self.loading = false
          })
      } else {
        this.finish()
      }
    },
    getUrlImage (path) {
      return appUtils.getImageURL(path)
    },
    finish () {
      this.doctorsSelected = []
      this.members = []
      this.$emit('refresh')
    },
    selectEmail () {
      if (this.search) {
        if (!this.validateEmail(this.search)) {
          alert('Vui lòng nhập đúng định dạng email')
          return
        }
        this.show_list_mem = false
        const user = {
          id: null,
          email: this.search,
          name: this.search
        }
        if (!this.members.find(u => u.email === user?.email)) {
          this.members.push(user)
        }
        setTimeout(() => {
          this.search = ''
        }, 50)
      }
    },
    selectDr (dr) {
      this.search = ''
      const user = dr.user
      if (this.room_members.find(u => u.user?.email === user?.email)) {
        alert('Thành viên đã ở trong  phòng')
        return
      }
      if (!this.members.find(u => u.email === user?.email)) {
        this.members.push(user)
      }
    },
    deselectDr (user) {
      const indexUser = this.members.findIndex(u => u.email === user?.email)
      if (indexUser > -1) {
        this.members.splice(indexUser, 1)
      }
    },
    focusOutSearchDoctor () {
      setTimeout(() => {
        this.show_list_mem = false
      }, 150)
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    handleSelectDoctor (value) {
      // console.log(value)
      if (value.length && !this.validateEmail(value.slice(-1)[0])) {
        this.$message({
          type: 'error',
          message: 'Vui lòng nhập đúng định dạng email'
        })
        this.doctorsSelected.pop()
      }
    },
    handleFilters (query) {
      if (query !== '') {
        this.isFiltering = true
        setTimeout(() => {
          this.isFiltering = false
          const drs = this.filteredAndSorted()
          this.doctorsData = drs.filter(item => {
            return (
              appUtils
                .removeVietnameseTones(item?.user?.name?.toLowerCase())
                .indexOf(appUtils.removeVietnameseTones(query.toLowerCase())) >
              -1 ||
              item?.user?.email
                ?.toLowerCase()
                .indexOf(appUtils.removeVietnameseTones(query.toLowerCase())) >
              -1
            )
          })
        }, 200)
      } else {
        this.doctorsData = this.filteredAndSorted()
      }
    },
    openModal (show) {
      if (show) {
        this.getConectiveDoctors()
      }
      window.$('#modalAddMeetMember').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .member-wraper {
    width: 100%;
    height: calc(100vh - 500px);
    position: relative;
  }

  .save-btn {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .box-member {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 10px;
  }

  .img-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #20419b;
  }

  .list-item-name {
    width: calc(100% - 60px);
  }

  .list-item-name span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .dropdown-list-m {
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .option-grid {
    display: grid;
    grid-template-areas:
      'option-avatar option-name'
      'option-avatar option-email';
    grid-template-columns: 42px 1fr;
    line-height: 1.2;

    .option-avatar {
      grid-area: option-avatar;

      .img-avatar {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .option-name,
    .option-email {
      font-size: 14px;
    }

    .option-name,
    .price {
      font-weight: bold;
    }

    .option-name {
      grid-area: option-name;
    }

    .option-email {
      grid-area: option-email;
    }
  }

  .el-select-dropdown__item {
    height: auto;
    line-height: 1;
  }

  .el-select {
    .el-select__tags {
      max-height: 300px;
      overflow: auto;
      max-width: unset !important;
    }
  }
}
</style>
